<!--门店模版-->
<template>
  <div class="shop-show-config">
    <!--内容案例-->
    <div class="g-ui-layout" style="align-items: baseline;margin-top: 24px;">
      <div class="g-layout-label is-required">内容案例</div>
      <ul class="case-items">
        <li v-for="item in themeList" :key="item.contentId" :class="{'selected-theme': item.check}" @click="selectedTheme(item)">
          <Checkbox v-model="item.check" @change="selectedTheme(item)" />
          <span class="theme-name">{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <!--媒体库-->
    <Medias :medias-show.sync="isShowMedias" :data-files.sync="dataFiles" />

  </div>
</template>

<script>
import { activityContentList, activityContentTree } from '@/api/businessCard'
import { Checkbox } from 'element-ui'
export default {
  name: 'sidebarModel',
  components: { Checkbox },
  props: {
    componentData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      themeList: [],
      isShowMedias: false,
      dataFiles: []
    }
  },
  watch: {
    'dataFiles': function() {
      this.updateMedias()
    }
  },
  created() {
    this.init()
  },
  methods: {
    delImg() {
      this.$set(this.componentData.configData, 'firstImg', '')
    },
    // 媒体库选择图片
    updateMedias() {
      if (this.dataFiles && this.dataFiles.length) {
        this.$set(this.componentData.configData, 'firstImg', this.dataFiles[0].url)
      } else {
        this.$set(this.componentData.configData, 'firstImg', '')
      }
    },
    // 初始化
    init() {
      let defaultIds = []

      // 是否编辑模式
      if (this.componentData.activeData.editMode === '1') {
        // 默认选择数据
        defaultIds = this.componentData.configData.activityContentIdList
      }

      // 获取主题
      activityContentList({ type: 'SERIES' }).then(res => {
        res.data.forEach(v => {
          v.check = defaultIds ? defaultIds.includes(v.contentId) : false
        })
        this.themeList = res.data
        this.getData()
      })
    },

    // 选择主题
    selectedTheme(item) {
      item.check = !item.check
      this.getData()
    },

    // 获取数据
    getData() {
      const checkItems = this.themeList.filter(v => v.check)
      if (checkItems.length) {
        const ids = checkItems.map(v => v.contentId)
        activityContentTree({
          targetContentIdList: ids.join(',')
        }).then(res => {
          // 处理数据格式
          this.$set(this.componentData.configData, 'shopShowConfig', res.data)
          this.$set(this.componentData.configData, 'activityContentIdList', ids)
        })
      } else {
        this.$set(this.componentData.configData, 'shopShowConfig', [])
        this.$set(this.componentData.configData, 'activityContentIdList', [])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-show-config{
  padding-bottom: 40px;
  .case-layout{
    padding-top: 12px;
  }
  .case-items{
    display: flex;
    flex-wrap: wrap;
    li{
      box-shadow: $cardShadow;
      border-radius: 4px;
      margin: 0 12px 12px 0;
      color: $title1;
      font-weight: 600;
      padding: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .theme-name{
        margin-left: 12px;
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .selected-theme{
      box-shadow: 0 0 7px 0 rgb(24,144,255);
    }
  }
  .upload-btn{
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    box-shadow: $cardShadow;
    border-radius: 4px;
    font-size: 40px;
    font-weight: normal;
    color: $title1;
    cursor: pointer;
    margin: 0 12px 12px 0;
  }
  .first-img{
    display: inline-block;
    width: 120px;
    height: 120px;
    border-radius: 4px;
    border: 1px $themeBg solid;
    cursor: pointer;
    position: relative;
    .first-img-del{
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 20px;
      color: $title1;
      cursor: pointer;
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .tiling-radio{
    border-radius: 2px;
    ul{
      width: 188px;
      padding: 28px 12px 12px;
      background: $themeBg;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      .tiling-item{
        width: 100%;
        padding: 12px 0;
        text-align: center;
        margin-top: 12px;
        border-radius: 2px;
        background: #fff;
        font-size: 26px;
        color: #e0f0f9;
      }
    }
  }
  .preview-radio-title{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: $title1;
    font-weight: 600;
    padding-bottom: 24px;
  }
  .tiling-head{
    background: #fafafa;
    padding: 12px 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-title{
      display: inline-block;
      width: 26px;
      height: 4px;
      background: #d9d9d9;
      border-radius: 10px;
    }
  }
  .preview-radio{
    border-radius: 2px;
    margin-left: 24px;
    vertical-align: bottom;
    position: relative;
    .preview-item{
      width: 188px;
      height: 226px;
      padding: 28px 12px 12px;
      background: $themeBg;
      border-radius: 4px;
      position: relative;
    }
    .preview-item-dialog{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(217, 217, 217, .8);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 40px;
      .dialog-arrow{
        position: absolute;
        left: -50px;
        top: 50%;
        transform: translateY(-50%);
        color: #d9d9d9;
      }
      .dialog-arrow-right{
        position: absolute;
        right: -50px;
        top: 50%;
        transform: translateY(-50%);
        color: #d9d9d9;
      }
    }
  }
  .preview-content{
    padding: 0 48px;
    border-radius: 4px;
  }
  .el-radio{
    padding: 12px 12px 24px;
    border: 1px $themeBg solid;
    border-radius: 4px;
  }
  .show-type{
    .is-checked{
      box-shadow: 0 0 7px 0 rgb(24,144,255);
    }
  }
}
</style>
